@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Baskerville';
    src: local('Baskerville'), url("./fonts/Baskervville-Regular.ttf") format('truetype');
}

@font-face {
    font-family: "Sawasdee";
    src: local('Sawasdee'),  url("./fonts/Sawasdee.ttf") format('truetype');
}

body {
    font-family: "Sawasdee";
    font-weight: 500;
    background: "#fffffa";
    color: rgba(99, 45, 44, 1);
}


.play-bkg {
    background-image: url("./assets/site/play-bkg.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    opacity: 1;
    
}

.play-btn {
    background-image: url("./assets/site/play-btn.png");
    background-repeat: no-repeat;
    height: 400px;
    width: 480px;    
}

.wordFromWordList:hover {
    color: rgb(251, 191, 36) !important;
    font-size:xx-large;
}

.write-bkg {
    background-image: url("./assets/site/write.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    opacity: 1;
    
}

.pinboard {
    background-image: url("./assets/site/drawers/pinboard-tile.png");

    background-repeat: repeat;
}

.wood-background {
    background-color: #fff7e2;
    opacity: 1;
    background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #f5e5be 4px ), repeating-linear-gradient( #b3793355, #ecbd84 );
}

.note-form {
    --paper-dark: #e5c93d;
    --paper-color: #ffed87;
    background: linear-gradient(
        135deg,
        var(--paper-dark),
        30%,
        var(--paper-color)
    );
}

.note {
    --paper-dark: #e5c93d;
    --paper-color: #ffed87;
    
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 125px;
    min-height: 75px;
    background: linear-gradient(
        135deg,
        var(--paper-dark),
        30%,
        var(--paper-color)
    );
    box-shadow: 3px 3px 2px var(--paper-shadow);
    transform: rotate(10deg);
    transform-origin: top left;      
}

.char-note-form {
    --paper-dark: #acd6f8;
    --paper-color: #97c5fa;
    background: linear-gradient(
        135deg,
        var(--paper-dark),
        30%,
        var(--paper-color)
    );
}

.char-note {
    --paper-dark: #acd6f8;
    --paper-color: #97c5fa;
    
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 125px;
    min-height: 75px;
    background: linear-gradient(
        135deg,
        var(--paper-dark),
        30%,
        var(--paper-color)
    );
    box-shadow: 3px 3px 2px var(--paper-shadow);
    transform: rotate(3deg);
    transform-origin: top left;      
}

.scene-note-form {
    --paper-dark: #89e53d;
    --paper-color: #9bff87;
    background: linear-gradient(
        135deg,
        var(--paper-dark),
        30%,
        var(--paper-color)
    );
}

.scene-note {
    --paper-dark: #89e53d;
    --paper-color: #9bff87;
    
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 125px;
    min-height: 75px;
    background: linear-gradient(
        135deg,
        var(--paper-dark),
        30%,
        var(--paper-color)
    );
    box-shadow: 3px 3px 2px var(--paper-shadow);
    transform: rotate(-10deg);
    transform-origin: top left;      
}

.paper p {
    margin: auto;
  }