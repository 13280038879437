*, *::before, *::after {
    box-sizing: border-box;
}

body {
    background-color: rgb(255, 255, 255);
    width: 100%;
    margin: 0px;
}

.container {
    margin: 0px;
    max-width: 100%;
}

.ql-editor {
    background-color: #FBE8A6;
    width: 8.5in;
    height: 11in;
    margin: 1rem 1rem 1rem 5rem;
    border-left: 1px solid #e91f1f;
    overflow-y: hidden;

}

.container .ql-editor.ql-snow {
    justify-content: center;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
    background-color: #FBE8A6;
}

.container .ql-container.ql-snow {
    width: 8.5in;
    min-height: 11in;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    background: #FBE8A6;
    z-index: 0;
}

.container .ql-toolbar.ql-snow{
    /* background-color: #FBE8A6 !important; */
    display: flex;
    justify-content: center;
    position: sticky;
    width: 102%;
    background-color: #f3f3f3;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .5);
    z-index: 0;
}

@page {
    margin: 1in;
}

@media screen {
    .container .ql-container.ql-snow {
        margin-left: 2.5in;
    }
}

@media print {
    body {
        background: none;
    }

    .container .ql-editor {
        width: 6.5in;
        height: 9in;
        padding: 0;
        margin: 0;
        box-shadow: none;
        align-self: flex-start;
    }

    .container .ql-toolbar.ql-snow {
        display: none;
    }
}
